<template>
  <v-card>
    <v-card-title>
      Acompanhamento Anual
    </v-card-title>

    <v-divider class="mt-0"></v-divider>

    <!-- filter quicksearch -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
        >
          <v-text-field
            :append-icon="icons.mdiMagnify"
            label="Buscar Vendedor"
            clearable
            outlined
            hide-details
            dense
            class="user-search mb-4"
            @click:append=""
            @click:clear=""
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="resumeList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      fixed-header
      group-by=""
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--grouping-->
      <template v-slot:group.header="{items}">
        <td colspan="10">
          {{ items[0].vendor_cpf }}
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiAutorenew,
      mdiCalendar,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiExportVariant,
      mdiEyeOutline,
      mdiFileDocumentOutline,
      mdiFilter,
      mdiFilterMenu,
      mdiFilterRemove,
      mdiLoading,
      mdiMagnify,
      mdiPencilOutline,
      mdiPlus,
      mdiSquareEditOutline,
      mdiTextBoxSearch,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      vendor_cpf: '',
    },

    /* data table */
    resumeList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: false,
    headers: [],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      resumeListItem: {},
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getList()
    },

    /* data table methods */
    async getList() {
      const months = []
      for (let i = 0; i < 12; i++) {
        const date = new Date()
        date.setMonth(date.getMonth() - i)
        months.push(date.toLocaleString('pt-BR', { month: 'short' }).toUpperCase())
      }
      const monthsObj = {}
      months.forEach((month, index) => {
        monthsObj[month] = `MES${index + 1}`
      })
      this.headers = Object.keys(monthsObj).map(month => ({ text: month, value: monthsObj[month] }))
      this.headers.push(
        {
          text: 'TOTAL',
          value: 'total',
        },
        {
          text: 'MEDIA',
          value: '',
          sortable: false,
        },
        {
          text: 'META',
          value: '',
          sortable: false,
        },
        {
          text: '%',
          value: '',
          sortable: false,
        },
      )
    },
  },
}
</script>
