import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Acompanhamento Anual ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}},[_c(VTextField,{staticClass:"user-search mb-4",attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar Vendedor","clearable":"","outlined":"","hide-details":"","dense":""},on:{"click:append":function($event){},"click:clear":function($event){}}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.resumeList,"loading":_vm.loading,"loading-text":"Carregando dados...","page":_vm.pageOptions.page,"items-per-page":_vm.pageOptions.itemsPerPage,"height":_vm.heightDinamic,"hide-default-footer":"","disable-sort":"","fixed-header":"","group-by":""},on:{"update:page":function($event){return _vm.$set(_vm.pageOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{attrs:{"colspan":"10"}},[_vm._v(" "+_vm._s(items[0].vendor_cpf)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }